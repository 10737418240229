import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainApp from "./MainApp"; // Your current app, refactored to a separate component
import CosmoprofApp from "./CosmoprofApp"; // Your completely separate Cosmoprof app
import Analysis from "./Analysis";
import "./index.css";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/cosmoprof" element={<CosmoprofApp />} />
        <Route path="/analysis" element={<Analysis />} />
      </Routes>
    </BrowserRouter>
  );
} else {
  console.error("Failed to find the root element");
}
