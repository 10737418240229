// MainApp.tsx
import React, { useEffect, useContext, useState } from "react";
import Header from "./components/Header";
import CameraComponent from "./components/Camera";
import Survey from "./components/Survey";
import ModelLoadingContext, {
    ModelLoadingProvider,
  } from "./contexts/ModelLoadingContext";
import useProducts from "./utils/hooks/useProducts";
import * as faceapi from "face-api.js";
import PCView from "./PCView";
import "./App.css";

const MainApp: React.FC = () => {
  const [view, setView] = useState(true);
  const { setIsModelLoaded } = useContext(ModelLoadingContext);
  const { products, getProducts } = useProducts();

  // Detect if the user is on a mobile device
  const [isMobile] = useState(
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
      navigator.userAgent
    )
  );

  const changeView = (set: boolean) => {
    setView(set);
  };

  useEffect(() => {
    async function loadModels() {
      getProducts();
      try {
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        ]);
        setIsModelLoaded(true);
      } catch (error) {
        console.error("Error loading models:", error);
      }
    }

    if (isMobile) {
      loadModels();
    }
  }, [isMobile, getProducts, setIsModelLoaded]);

  return (
    <>
      {isMobile ? (
        <ModelLoadingProvider>
          {/* Render the main app components */}
          {/* Header, Survey, or Camera based on view */}
          <>
            <Header />
            {!view ? (
              <CameraComponent products={products} />
            ) : (
              <Survey onComplete={() => changeView(false)} />
            )}
          </>
        </ModelLoadingProvider>
      ) : (
        <PCView />
      )}
    </>
  );
};

export default MainApp;
