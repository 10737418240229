import * as faceapi from "face-api.js";
import { isFaceAlignedAndStraight } from "./straightFace";
import Webcam from "react-webcam";
import { calculateAverageBrightness } from "./calculateAverageBrightness";

export async function detectFace(
  isModelLoaded: boolean,
  webcamRef: React.RefObject<Webcam>,
  setIsStraight: React.Dispatch<React.SetStateAction<"good" | "ok" | "bad">>,
  setIsFaceInShape: React.Dispatch<React.SetStateAction<"good" | "ok" | "bad">>,
  setIsLightGood: React.Dispatch<React.SetStateAction<"good" | "ok" | "bad">>
) {
  try {
    if (webcamRef.current) {
      const video = webcamRef?.current?.video as HTMLVideoElement;
      const detections: any = await faceapi
        .detectSingleFace(video, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks();

      if (detections) {
        const { detection, landmarks } = detections;

        const isAlignedAndStraight = isFaceAlignedAndStraight(landmarks);
        setIsStraight(isAlignedAndStraight);

        const minXok = -20;
        const maxXok = 300;
        const minYok = 150;
        const maxYok = 460;

        if (
          detection.box.x >= minXok &&
          detection.box.x <= maxXok &&
          detection.box.y >= minYok &&
          detection.box.y <= maxYok
        ) {
          setIsFaceInShape("good");
        } else {
          setIsFaceInShape("bad");
        }

        const brightness = await calculateAverageBrightness(video);

        if (brightness >= 40 && brightness <= 160) {
          setIsLightGood("good");
        } else {
          setIsLightGood("bad");
        }
      } else {
        setIsStraight("bad");
        setIsFaceInShape("bad");
        setIsLightGood("bad");
      }
    }
  } catch (error) {
    console.error("Error detecting face:", error);
    setIsStraight("bad");
    setIsFaceInShape("bad");
    setIsLightGood("bad");
  }
}
