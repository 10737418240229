// Analysis.tsx
import React, { useEffect, useContext, useState } from "react";
import Header from "./components/Header";
import CameraComponent from "./components/Camera";
import ModelLoadingContext, { ModelLoadingProvider } from "./contexts/ModelLoadingContext";
import useProducts from "./utils/hooks/useProducts";
import * as faceapi from "face-api.js";
import PCView from "./PCView";
import { useSearchParams } from "react-router-dom";
import "./App.css";

const Analysis: React.FC = () => {
  // Extract and set survey data from the URL, if available.
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const dataParam = searchParams.get("data");
    if (dataParam) {
      try {
        const decodedData = decodeURIComponent(dataParam);
        const parsedData = JSON.parse(decodedData);
        if (parsedData.prompt) localStorage.setItem("prompt", parsedData.prompt);
        if (parsedData.email) localStorage.setItem("email", parsedData.email);
        if (parsedData.sendRoutine) localStorage.setItem("sendRoutine", parsedData.sendRoutine);
      } catch (error) {
        console.error("Error decoding survey data:", error);
      }
    }
  }, [searchParams]);

  // Check if the user is on a mobile device.
  const [isMobile] = useState(
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
      navigator.userAgent
    )
  );

  const { products, getProducts } = useProducts();
  const { setIsModelLoaded } = useContext(ModelLoadingContext);
  const [modelsLoaded, setModelsLoaded] = useState(false);

  useEffect(() => {
    async function loadModels() {
      // Load products first.
      getProducts();
      try {
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        ]);
        setIsModelLoaded(true);
        setModelsLoaded(true);
      } catch (error) {
        console.error("Error loading models:", error);
      }
    }
    if (isMobile) {
      loadModels();
    }
  }, [isMobile, getProducts, setIsModelLoaded]);

  return (
    <>
      {isMobile ? (
        <ModelLoadingProvider>
          <Header />
          {modelsLoaded ? (
            <CameraComponent products={products} />
          ) : (
            <div style={{ textAlign: "center", marginTop: "20px" }}>Loading models...</div>
          )}
        </ModelLoadingProvider>
      ) : (
        <PCView />
      )}
    </>
  );
};

export default Analysis;
