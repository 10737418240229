import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ThemeProvider,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  styled,
  TextField,
  Checkbox,
  Slide,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import PostSurveyView from "./PostSurvey";

// Updated StyledContainer to center the questionnaire
const StyledContainer = styled(Container)(({ theme }) => ({
  width: "350px", // Take full width of the parent
  height: "85vh", // Take full viewport height
  display: "flex",
  justifyContent: "center", // Center horizontally
  alignItems: "center", // Center vertically
  fontFamily: "'neue_hans_kendrickregular'",
  position: "relative",
  overflow: "hidden",
  padding: theme.spacing(2),
  textAlign: "center",
}));

// Updated QuestionContainer to ensure content is centered
const QuestionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // Center vertically
  alignItems: "center", // Center horizontally
  width: "100%",
  maxWidth: "400px", // Limit the maximum width of the questionnaire
});

// Rest of the styles remain the same
const OptionButton = styled(Button)(({ theme, selected }) => ({
  margin: "10px auto",
  width: "350px",
  height: 45,
  padding: theme.spacing(2, 0), // Vertical padding only
  fontFamily: "'neue_hans_kendrickregular'",
  textAlign: "center",
  borderRadius: 20,
  border: "1px solid grey",
  backgroundColor: selected ? "black" : "inherit",
  color: selected ? theme.palette.common.white : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: selected ? "black" : theme.palette.action.hover,
  },
}));

const ButtonsContainer = styled("div")({
  marginTop: 5,
  width: "350px",
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const NextButton = styled(Button)({
  width: "165px",
  height: 45,
  borderRadius: 20,
  color: "black",
  border: "1px solid black",
  fontFamily: "'neue_hans_kendrickregular'",
  "&:hover": {
    backgroundColor: "black",
    color: "white",
  },
});

const StyledTextField = styled(TextField)({
  margin: "8px auto",
  width: "80%",
  "& label.Mui-focused": {
    color: "black",
  },
});

const Survey = ({ onComplete }) => {
  const [showPostSurveyView, setShowPostSurveyView] = useState(false);
  const [showEmailStep, setShowEmailStep] = useState(false);
  const [direction, setDirection] = useState("left");
  const [showSlide, setShowSlide] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const duration = 600;

  useEffect(() => {
    localStorage.removeItem("prompt");
    localStorage.removeItem("email");
  }, []);

  const initialFormData = {
    steps: [
      {
        question: "How does your skin feel during the day?",
        options: [
          { label: "DRY AND ROUGH TO TOUCH", value: "dry and rough on touch" },
          { label: "TIGHT AND LOOKS DULL", value: "tight and looks dull" },
          {
            label: "OILY, WITH NOTICABLE PORES",
            value: "oily, with noticeable pores",
          },
          {
            label: "SENSITIVE AND UNCOMFORTABLE",
            value: "sensitive and uncomfortable",
          },
          {
            label: "I'M HAPPY WITH HOW IT FEELS",
            value: "I'm happy with how it feels",
          },
        ],
      },
      {
        question: "What is your skin goal?",
        options: [
          {
            label: "SMOOTH, FIRM AND LIFTED",
            value: "smooth, firm and lifted",
          },
          {
            label: "SOFT, NOURISHED AND RADIANT",
            value: "soft, nourished and radiant",
          },
          {
            label: "BALANCED, MATIFIED AND REFINED",
            value: "balanced, matified and refined",
          },
          {
            label: "CALM AND COMFORTABLE",
            value: "calm, comfortable and soothed",
          },
          {
            label: "VISIBLY EVEN AND SMOOTH",
            value: "visibly even and smooth",
          },
        ],
      },
      {
        question: "How do you like your skincare to feel?",
        options: [
          { label: "NOURISHING", value: "nourishing" },
          { label: "LIGHTWEIGHT", value: "lightweight" },
        ],
      },
    ],
  };

  const { handleSubmit } = useForm();

  const handleNext = () => {
    if (
      answers[currentStep]?.selectedOption &&
      currentStep < initialFormData.steps.length - 1
    ) {
      setDirection("left");
      setShowSlide(false);
      setTimeout(() => {
        setCurrentStep((prev) => prev + 1);
        setShowSlide(true);
      }, duration);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setDirection("right");
      setShowSlide(false);
      setTimeout(() => {
        setCurrentStep((prev) => prev - 1);
        setShowSlide(true);
      }, duration);
    }
  };

  const onSubmit = async () => {
    if (showEmailStep) {
      setShowPostSurveyView(true);
      localStorage.setItem("email", email);
      localStorage.setItem(
        "prompt",
        `question: How does your skin feel during the day? answer: ${answers[0]?.selectedOption}; 
         question: What is your skin goal? answer: ${answers[1]?.selectedOption}; 
         question: How do you like your skincare to feel? answer: ${answers[2]?.selectedOption}`
      );
      localStorage.setItem("sendRoutine", isSubscribed ? "true" : "false");
      try {
        await fetch("https://mid-code.onrender.com/save-email", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setShowEmailStep(true);
      setShowSlide(false);
      setTimeout(() => {
        setShowSlide(true);
      }, duration);
    }
  };

  const handleOptionSelect = (selectedOption) => {
    setAnswers((prev) => {
      const newAnswers = [...prev];
      newAnswers[currentStep] = { selectedOption };
      return newAnswers;
    });
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidEmail(emailRegex.test(emailValue));
  };

  const theme = createTheme({
    palette: { primary: { main: "#000000" } },
  });

  const handleCancel = () => setShowPostSurveyView(false);

  return (
    <StyledContainer>
      {showPostSurveyView ? (
        <PostSurveyView onCancel={handleCancel} onComplete={onComplete} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Slide
            direction={direction}
            in={showSlide}
            mountOnEnter
            unmountOnExit
            timeout={duration}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <QuestionContainer>
                {!showEmailStep ? (
                  <>
                    <p
                      style={{
                        fontSize: "30px",
                        marginBottom: 20,
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      {initialFormData.steps[currentStep].question}
                    </p>
                    <FormControl component="fieldset">
                      {initialFormData.steps[currentStep].options.map(
                        (option) => (
                          <FormControlLabel
                            sx={{ marginLeft: 0, marginRight: 0 }}
                            key={option.value}
                            control={
                              <OptionButton
                                selected={
                                  option.value ===
                                  answers[currentStep]?.selectedOption
                                }
                                onClick={() => handleOptionSelect(option.value)}
                              >
                                {option.label}
                              </OptionButton>
                            }
                            label={null}
                          />
                        )
                      )}
                    </FormControl>
                    <ButtonsContainer>
                      <NextButton
                        variant="outlined"
                        onClick={handlePrev}
                        disabled={currentStep === 0}
                      >
                        Back
                      </NextButton>
                      {currentStep < initialFormData.steps.length - 1 ? (
                        <NextButton
                          variant="contained"
                          onClick={handleNext}
                          disabled={!answers[currentStep]?.selectedOption}
                          sx={{
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "black" },
                            color: "white",
                            border: "none",
                          }}
                        >
                          Next
                        </NextButton>
                      ) : (
                        <NextButton
                          variant="contained"
                          type="submit"
                          sx={{
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "black" },
                            color: "white",
                            border: "none",
                          }}
                        >
                          Submit
                        </NextButton>
                      )}
                    </ButtonsContainer>
                  </>
                ) : (
                  <ThemeProvider theme={theme}>
                    <p
                      style={{
                        fontSize: "30px",
                        marginBottom: 20,
                        textAlign: "center",
                      }}
                    >
                      Enter your email to continue
                    </p>
                    <StyledTextField
                      label="Email"
                      variant="standard"
                      value={email}
                      onChange={handleEmailChange}
                      helperText={!validEmail ? "Invalid email" : ""}
                    />
                    <ButtonsContainer>
                      <NextButton
                        variant="outlined"
                        onClick={() => {
                          setShowEmailStep(false);
                          setShowSlide(false);
                          setTimeout(() => {
                            setShowSlide(true);
                          }, duration);
                        }}
                      >
                        Back
                      </NextButton>
                      <NextButton
                        variant="contained"
                        type="submit"
                        disabled={!validEmail}
                        sx={{
                          backgroundColor: "black",
                          "&:hover": { backgroundColor: "black" },
                          color: "white",
                        }}
                      >
                        Continue
                      </NextButton>
                    </ButtonsContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSubscribed}
                          onChange={() => setIsSubscribed(!isSubscribed)}
                        />
                      }
                      label="Email me the routine after scan"
                      sx={{ marginTop: 5, marginLeft: 0, marginRight: 0 }}
                    />
                  </ThemeProvider>
                )}
              </QuestionContainer>
            </div>
          </Slide>
        </form>
      )}
    </StyledContainer>
  );
};

export default Survey;
