// PostSurvey.js
import React from "react";
import { Button, Typography, styled, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";

// Use the provided base URL
const baseURL = "https://192.168.0.100:3000";

const StyledContainer = styled("div")({
  textAlign: "center",
  marginTop: "30px",
});

const ButtonContainer = styled("div")({
  display: "flex",
  gap: 30,
  alignItems: "center",
  justifyContent: "center",
  marginTop: 20,
});

const PostSurveyView = ({ onComplete, onCancel }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  // For very large screens (e.g. above 27 inches, adjust the min-width as needed)
  const isLargeScreen = useMediaQuery("(min-width:2560px)");
  const location = useLocation();
  const isCosmoprof = location.pathname === "/cosmoprof";

  // Retrieve stored data from localStorage
  const prompt = localStorage.getItem("prompt") || "";
  const email = localStorage.getItem("email") || "";
  const sendRoutine = localStorage.getItem("sendRoutine") || "";

  // Build an object with all the values and stringify it.
  const dataObj = { prompt, email, sendRoutine };
  const dataString = encodeURIComponent(JSON.stringify(dataObj));

  // Construct the link to /analysis using the provided baseURL.
  const analysisLink = `${baseURL}/analysis?data=${dataString}`;

  return (
    <StyledContainer>
      <Typography
        variant="h4"
        sx={{
          marginBottom: isSmallScreen ? 3 : 4,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        Scan your face
      </Typography>
      {/* Preparation Steps */}
      <Typography
        variant="h4"
        sx={{
          marginBottom: isSmallScreen ? 1 : 2,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        01.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: isSmallScreen ? 1 : 2,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        Pull hair back and remove glasses or anything that hides your skin.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          marginBottom: isSmallScreen ? 1 : 2,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        02.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: isSmallScreen ? 1 : 2,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        Try to use the same well-lit area for every scan.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          marginBottom: isSmallScreen ? 1 : 2,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        03.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: isSmallScreen ? 1 : 2,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        Relax your face. No smiling.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          marginBottom: 1,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        04.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 2,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        For best results, have clean skin without any makeup or products.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: 5,
          marginBottom: 2,
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        {isCosmoprof
          ? "SCAN THE QR CODE TO BEGIN ON YOUR PHONE"
          : "Once you have followed these steps, proceed with scanning your face."}
      </Typography>
      {isCosmoprof ? (
        // When on /cosmoprof, only display the QR code.
        <QRCodeCanvas
          value={analysisLink}
          size={isLargeScreen ? 512 : 256} // Increase size for very large screens
          includeMargin={true}
        />
      ) : (
        // Otherwise, display the buttons.
        <ButtonContainer>
          <Button
            variant="outlined"
            sx={{
              marginTop: isSmallScreen ? 1 : 2,
              minWidth: "160px",
              border: "1px solid grey",
              borderRadius: "20px",
              color: "black",
              fontWeight: "bold",
              height: 45,
              "&:hover": {
                border: "1px solid grey",
              },
            }}
            onClick={onCancel}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={onComplete}
            sx={{
              marginTop: isSmallScreen ? 1 : 2,
              minWidth: "160px",
              border: "1px solid grey",
              borderRadius: "20px",
              color: "white",
              fontWeight: "bold",
              backgroundColor: "black",
              height: 45,
              "&:hover": {
                backgroundColor: "black",
              },
            }}
          >
            Let's go
          </Button>
        </ButtonContainer>
      )}
    </StyledContainer>
  );
};

export default PostSurveyView;
